export const TOGGLE_CONTACT_MODAL = 'TOGGLE_CONTACT_MODAL';

export const TOGGLE_AUTH_MODAL = 'TOGGLE_AUTH_MODAL';

export const TOGGLE_DATA_ADMIN_MODAL = 'TOGGLE_DATA_ADMIN_MODAL';

export const TOGGLE_ADD_ADMIN_MODAL = 'TOGGLE_ADD_ADMIN_MODAL';

export const SET_STUDENTS = 'SET_STUDENTS';

export const ERROR_MODAL_ACTIVATOR = 'ERROR_MODAL_ACTIVATOR';

export const INFO_TYPE_CHANGE = 'INFO_TYPE_CHANGE';

export const LOAD_GROUPS = 'LOAD_GROUPS';

export const SELECT_INPUT_CHOOSE_TITLE = 'SELECT_INPUT_CHOOSE_TITLE';

export const LOADING_GROUPS_FAILED = 'LOADING_GROUPS_FAILED';

export const ADD_NEW_DATA = 'ADD_NEW_DATA';

export const DELETE_DATA = 'DELETE_DATA';

export const LOADING_DATA_FAILED = 'LOADING_DATA_FAILED';

export const LOAD_DATA = 'LOAD_DATA';

export const LOADED_DATA = 'LOADED_DATA';

export const ID_PROVIDER = 'ID_PROVIDER';

export const LOADING = 'LOADING';

export const ADD_COURSE_TO_BASKET = 'ADD_COURSE_TO_BASKET';

export const TOGGLE_BACKDROP = 'TOGGLE_BACKDROP';

export const TOGGLE_MODAL = 'TOGGLE_MODAL';

export const LOAD_COURSES = 'LOAD_COURSES';

export const LOADING_COURSES_FAILED = 'LOADING_COURSES_FAILED';

export const SEND_EMAIL_MESSAGE = 'SEND_EMAIL_MESSAGE';

export const APP_SETTINGS = 'APP_SETTINGS';

export const SETTINGS_INFO_TYPE_CHANGE = 'SETTINGS_INFO_TYPE_CHANGE';

export const LOAD_WARNING_MODAL_PAYLOAD = 'LOAD_WARNING_MODAL_PAYLOAD';

export const UPDATED_DATA = 'UPDATED_DATA';

export const UPDATE_LOADED_DATA = 'UPDATE_LOADED_DATA';

export const LOAD_IMAGE_FILE = 'LOAD_IMAGE_FILE';

export const ARCHIVE_MODE = 'ARCHIVE_MODE';